<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Match the solution in the picture below with its solubility:</p>

      <p class="pl-8 mb-8">
        <v-img style="max-width: 319px" :src="imageName" />
      </p>

      <p class="mb-2">
        A:
        <v-select
          v-model="inputs.input1"
          style="display: inline-block; max-width: 450px"
          class="ml-1"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        B:
        <v-select
          v-model="inputs.input2"
          style="display: inline-block; max-width: 450px"
          class="ml-1"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        C:
        <v-select
          v-model="inputs.input3"
          style="display: inline-block; max-width: 450px"
          class="ml-1"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'Chem1LD_Q3S1_Question1',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      items: [
        {text: 'Insoluble', value: 'insoluble'},
        {text: 'Partially soluble', value: 'partial'},
        {text: 'Completely soluble', value: 'soluble'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/Chem1LD_Q3S1_Question1.jpg';
    },
  },
};
</script>
<style scoped></style>
