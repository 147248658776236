var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v("Match the solution in the picture below with its solubility:")]),_c('p',{staticClass:"pl-8 mb-8"},[_c('v-img',{staticStyle:{"max-width":"319px"},attrs:{"src":_vm.imageName}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" A: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" B: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" C: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }